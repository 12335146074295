import { AutoMotorResultListUserZoom } from '@bbx/common/components/UserZoom/UserZoom'
import { getSeoSearchResult } from '@bbx/search-journey/common/api/searchApiClient'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { AutoMotorResultListContainer } from '@bbx/search-journey/sub-domains/search/components/verticals/auto-motor/result-list/AutoMotorResultListContainer'
import { ErrorPage } from '@wh/common/chapter/components/ErrorPage/ErrorPage'
import type { Layout } from '@wh/common/chapter/components/Layouts/Layout'
import { LeaderboardAndSkyscraperLayout } from '@wh/common/chapter/components/Layouts/LeaderboardAndSkyscraperLayout'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { concatPathWithQueryParams, getFirstString } from '@wh/common/chapter/lib/urlHelpers'
import { ExpressNextContextWithUserData } from '@wh/common/chapter/types/nextJS'
import { NextPage } from 'next'
import React, { Fragment } from 'react'

type ResultListProps =
    | {
          searchResult: SearchResult
          fromExpiredAdId?: string | undefined
      }
    | {
          is404: true
      }

const ResultList: NextPage<ResultListProps> & { Layout: typeof Layout } = (props) => {
    if ('is404' in props) {
        return <ErrorPage statusCode={404} />
    } else {
        return (
            <Fragment>
                <AutoMotorResultListUserZoom />
                <AutoMotorResultListContainer searchResult={props.searchResult} fromExpiredAdId={props.fromExpiredAdId} />
            </Fragment>
        )
    }
}

const resultListGetInitialProps = async ({ query, req, res }: ExpressNextContextWithUserData): Promise<ResultListProps> => {
    const { seopath, fromExpiredAdId: rawFromExpiredAdId, ...remainingQuery } = query
    const path = ['/gebrauchtwagen', ...(seopath as string[])].join('/')
    const fromExpiredAdId = getFirstString(rawFromExpiredAdId)

    if (!path) {
        if (res) {
            res.statusCode = 404
        }
        return { is404: true }
    }

    try {
        const concatenatedPath = concatPathWithQueryParams(path, remainingQuery)
        const searchResult = await getSeoSearchResult(concatenatedPath, req)
        return { searchResult, fromExpiredAdId }
    } catch (error) {
        const apiError = wrapInApiErrorIfNecessary(error)

        if (apiError.statusCode && apiError.statusCode >= 400 && apiError.statusCode <= 499) {
            if (res) {
                res.statusCode = 404
            }
            return { is404: true }
        }

        throw apiError
    }
}

ResultList.getInitialProps = resultListGetInitialProps
ResultList.Layout = LeaderboardAndSkyscraperLayout

export default ResultList
